<template>
    <div class="mb-4">
        <div class="row justify-content-start mb-6 align-items-center">
            <div class="col-4" v-if="hasBeneficiaries">
                <label>
                    {{ fields.beneficiary.label }}
                </label>
                <st-autocomplete
                    v-model="model[fields.beneficiary.name]"
                    :options="beneficiaries"
                    :name="fields.beneficiary.name"
                    :disabled="viewMode"
                    :fields="fields"
                    @change="getBeneficiary"
                >
                </st-autocomplete>
            </div>
        </div>
        <div class="row justify-content-start mb-4">
            <!-- Remove condition once foreigner option is decided to be available -->
            <div class="col-4" v-if="false">
                <p class="font-size-h6">
                    {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.HEADER") }}
                </p>
                <b-form-radio-group
                    size="lg"
                    v-model="selectedCitizenship"
                    :options="citizenship"
                    name="radio-options-citizenship"
                    :disabled="viewMode"
                ></b-form-radio-group>
            </div>
            <div class="col-4">
                <p class="font-size-h6">
                    {{ $t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.HEADER") }}
                </p>
                <b-form-radio-group
                    size="lg"
                    v-model="selectedPerson"
                    :options="person"
                    name="radio-options-person"
                    :disabled="viewMode"
                ></b-form-radio-group>
            </div>
        </div>

        <div v-if="selectedCitizenship === 'foreigner'">
            <foreigner-beneficiary-form
                :item="item"
                ref="foreignerBeneficiary"
                @change="onModelChange"
                :viewMode="viewMode"
            >
            </foreigner-beneficiary-form>
        </div>
        <div v-else>
            <citizen-beneficiary-form
                v-if="selectedPerson === 'citizen'"
                :item="selectedBeneficiary"
                ref="citizenBeneficiary"
                :viewMode="viewMode"
            >
            </citizen-beneficiary-form>
            <legal-beneficiary-form
                v-if="selectedPerson === 'legal_entity'"
                ref="legalBeneficiary"
                :item="selectedBeneficiary"
                :viewMode="viewMode"
            >
            </legal-beneficiary-form>
        </div>

        <div class="mt-6">
            <slot name="buttons" :submit="submit" :cancel="cancel" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CitizenBeneficiaryForm from "./CitizenBeneficiaryForm";
import ForeignerBeneficiaryForm from "./ForeignerBeneficiaryForm";
import LegalBeneficiaryForm from "./LegalBeneficiaryForm";
import { BeneficiaryModel } from "@/modules/applications/models/beneficiary-model";
import { FormSchema } from "@/shared/form/form-schema";

const { fields } = BeneficiaryModel;

const formSchema = new FormSchema([
    fields.beneficiary
]);

export default {
    name: "BeneficiaryFormTypes",
    components: {
       CitizenBeneficiaryForm,
       ForeignerBeneficiaryForm,
       LegalBeneficiaryForm,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
    },
    data() {
        return {
            citizenship: [
                {
                    text: this.$t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.ROMANIAN"),
                    value: "citizen",
                },
                {
                    text: this.$t("BENEFICIARY.BENEFICIARY_DETAILS.CITIZENSHIP.FOREIGNER"),
                    value: "foreigner",
                },
            ],
            selectedCitizenship: "citizen",
            selectedPerson: "citizen",
            fields,
            model: null,
            selectedBeneficiary: null,
        };
    },
    watch: {
        selectedCitizenship(value) {
            if (value === 'foreigner') this.selectedPerson = 'citizen';
        },
        selectedPerson(value) {
            if (!this.item || this.item?.user_type_name !== value) {
                this.selectedBeneficiary = undefined;
                this.model[this.fields.beneficiary.name] = undefined;
            }
        }
    },
    computed: {
        ...mapGetters({
            moduleName: "shared/moduleName",
            currentUser: "auth/currentUser",
        }),
        record() {
            const currentState = this.moduleName ?? 'applications';
            return this.$store.state[currentState]?.form?.record;
        },
        person() {
            const options = [{
                text: this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.INDIVIDUAL"),
                value: "citizen",
            }];

            if (this.selectedCitizenship === "citizen") {
                options.push({
                    text: this.$t("BENEFICIARY.BENEFICIARY_DETAILS.PERSON.LEGAL"),
                    value: "legal_entity",
                });
            }

            return options;
        },
        beneficiaries() {
            return this.fields?.beneficiary?.options?.filter((el) => el.user_type_name === this.selectedPerson);
        },
        hasBeneficiaries() {
            return this.beneficiaries?.length > 0;
        },
    },
    async created() {
        this.selectedBeneficiary = this.item;
        this.model = formSchema.initialValues(this.currentUser);
    },
    async mounted() {
        if (this.viewMode || this.item) {
            this.selectedPerson = this.item.user_type_name === "foreigner" ? "citizen" : this.item.user_type_name;
            this.selectedCitizenship = this.item.user_type_name !== "foreigner" ? "citizen" : "foreigner";
        } else {
            this.selectedPerson = this.currentUser.user_type_name === "foreigner" ? "citizen" : this.currentUser.user_type_name;
            this.selectedCitizenship = this.currentUser.user_type_name !== "foreigner" ? "citizen" : "foreigner";
        }

        //update beneficiaries list
        await this.fields.beneficiary.getOptions();
    },
    methods: {
        ...mapActions({
            findBeneficiary: 'applications/form/doFindBeneficiary'
        }),
        async submit() {
            this.$emit("submit", this.selectedBeneficiary);
        },
        cancel() {
            this.$emit("cancel");
        },
        async getBeneficiary() {
            if (this.model[this.fields.beneficiary.name]) {
                const beneficiary = await this.findBeneficiary(this.model[this.fields.beneficiary.name]);
                const selectedBeneficiary = this.selectedBeneficiary ?? { ...this.selectedBeneficiary };
                // reset all populated fields with empty string value
                if (selectedBeneficiary) {
                    Object.keys(selectedBeneficiary).forEach(key => selectedBeneficiary[key] = '');
                }
                this.selectedBeneficiary = {
                    ...selectedBeneficiary,
                    ...beneficiary,
                };
            }
        }
    },
};
</script>
