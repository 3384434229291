<template>
    <div>
        <st-button
            variant="primary"
            :callback="details"
        >
             {{ $t('DOCUMENT_SERIES_ALLOCATION.DETAILS_BUTTON') }}
        </st-button>
        <st-button
            variant="secondary"
            :callback="add"
            v-can:create="'documentSeriesAllocation'"
        >
            <i class="fa fa-plus"></i>
            {{ $t('DOCUMENT_SERIES_ALLOCATION.ADD_BUTTON') }}
        </st-button>
        <st-button
            variant="primary"
            :callback="exportCb"
            :spinner="isReportLoading"
        >
            <i class="fa fa-file"></i>
            {{ $t('DOCUMENT_SERIES_ALLOCATION.EXPORT_ALLOCATIONS_BUTTON') }}
        </st-button>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'DocumentSeriesAllocationListToolbar',
        computed: {
            ...mapGetters({
                loading: 'shared/loading',
                filters: 'documentSeriesAllocation/list/filters',
            }),
            isReportLoading() {
                return this.loading["documentSeriesAllocationList/exportAllocations"];
            }
        },
        methods: {
            ...mapActions({
                exportAllocations: 'documentSeriesAllocation/list/exportAllocations'
            }),
            add() {
                this.$emit('add');
            },
            details() {
                this.$emit('details');
            },
            async exportCb() {
                const params = {};
                Object.keys(this.filters)
                    .filter((el) => !['skip', 'limit', 'query', 'pagination'].includes(el))
                    .forEach(element => {
                        params[element] = this.filters[element];
                    });

                await this.exportAllocations(params);
            },
        }
    };
</script>
