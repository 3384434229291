<template>

    <div>
        <DocumentSeriesAllocationLocationsModal
            ref="documentSeriesAllocationLocationsModal"
            :editMode="editMode"
            @reloadLocationList="reloadLocationList"
        ></DocumentSeriesAllocationLocationsModal>

        <div class="d-flex align-items-end flex-column w-100 mt-10">
            <div class="form-group">
                <st-button
                    variant="outline-primary"
                    :callback="addLocation"
                    class="ml-3"
                    :disabled="false"
                >
                    <i class="fa fa-plus"></i>
                    {{$t('DOCUMENT_SERIES_ALLOCATION.FORM.ADD_LOCATION')}}
                </st-button>
            </div>
        </div>

        <st-data-table
            :items="rows"
            :fields="tableFields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="isLoading"
            @edit="editLocation"
            @delete="deleteFromTable"
            responsive="sm"
            hover
            stateModule="documentSeriesAllocation/locations"
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DocumentSeriesAllocationLocationModel } from '@/modules/document-series-allocation/document-series-allocation-location-model';
import DocumentSeriesAllocationLocationsModal from './DocumentSeriesAllocationLocationsModal.vue';

const { fields } = DocumentSeriesAllocationLocationModel;

export default {
    name: 'DocumentSeriesAllocationLocations',
    props: {
        locations: {
            type: Array,
            default: () => ([]),
        },
    },
    components: {
        DocumentSeriesAllocationLocationsModal,
    },
    data() {
        return {
            presenter: DocumentSeriesAllocationLocationModel.presenter,
            editMode: false,
        };
    },
    watch: {
        rows(vals) {
            this.$emit('locationsUpdated', vals);
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            rows: 'documentSeriesAllocation/locations/rows',
        }),
        tableFields() {
            return [
                fields.territorial_administrative_unit_id,
                fields.identification_number,
            ]
        },
        isLoading () {
            return false;
        },
        actions() {
            return [
                {
                    name: "edit",
                    icon: "edit",
                    tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                    customIcon: false,
                    type: "primary",
                },
                {
                    name: "delete",
                    icon: "trash-alt",
                    tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                    customIcon: false,
                    type: "danger",
                },
            ];
        }
    },
    methods: {
        ...mapActions({
            initLocations: 'documentSeriesAllocation/locations/doSetLocations',
            setActiveLocation: 'documentSeriesAllocation/locations/doSetActiveLocation',
            deleteLocation: 'documentSeriesAllocation/locations/doDeleteLocation',
        }),
        addLocation() {
            this.editMode = false;
            this.setActiveLocation({});
            this.$refs.documentSeriesAllocationLocationsModal.show(this.editMode);
        },
        editLocation(loc) {
            this.editMode = true;
            this.setActiveLocation(loc.item);
            this.$refs.documentSeriesAllocationLocationsModal.show(this.editMode);
        },
        deleteFromTable(loc) {
            this.deleteLocation(loc.item);
        },
        reloadLocationList() {
            this.$emit('reloadLocationsMap');
        }
    },
    async mounted() {
        this.initLocations(this.locations ?? []);
    },
};
</script>
