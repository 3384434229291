<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="isLoading"
            @view="onView"
            @edit="onEdit"
            responsive="sm"
            hover
            :serverSideSorting="true"
            stateModule="documentSeriesAllocation/list"
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { DocumentSeriesAllocationModel } from '@/modules/document-series-allocation/document-series-allocation-model';
    import { DocumentSeriesAllocationPermissions } from '@/modules/document-series-allocation/document-series-allocation-permissions';

    const { fields } = DocumentSeriesAllocationModel;

    export default {
        name: 'DocumentSeriesAllocationListTable',
        data() {
            return {
                presenter: DocumentSeriesAllocationModel.presenter,
            };
        },
        computed: {
            ...mapGetters({
                rows: 'documentSeriesAllocation/list/rows',
                loading: 'shared/loading',
                currentUser: 'auth/currentUser',
            }),
            fields() {
                return [
                    fields.created_by,
                    fields.created_date.extendField({ sortable: true}),
                    fields.allocated_series.extendField({ bold: true, sortable: true}),
                    fields.type_name,
                ]
            },
            isLoading () {
                return this.loading['documentSeriesAllocation/get'] ;
            },
            hasPermissionToEditDocumentSeriesAllocation() {
                return new DocumentSeriesAllocationPermissions(this.currentUser).edit;
            },
            actions() {
                const actions = [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    }
                ];
                if (this.hasPermissionToEditDocumentSeriesAllocation) {
                    actions.push(
                        {
                            name: "edit",
                            icon: "edit",
                            tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                            customIcon: false,
                            type: "primary",
                        },
                    )
                }
                return actions;
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'documentSeriesAllocation/form/select',
                deleteRecord: 'documentSeriesAllocation/form/remove',
            }),
            async onView(data) {
                this.$emit('view', data.item.allocation_recipient_id);
            },
            async onEdit(data) {
                this.$emit('edit', data.item);
            },
        },
    };
</script>
