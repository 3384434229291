<template>
    <div>
        <st-modal
            id="beneficiary-modal"
            hide-header-delimiter
            hide-footer-delimiter
            hide-footer
            hideable
            :noCloseOnBackdrop="true"
            size="xl"
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ modalTitle }}</p>
            </template>
            <template #body>
                <beneficiary-form
                    :item="item"
                    :items="items"
                    @submitForm="submit"
                    @cancelForm="cancel"
                ></beneficiary-form>
            </template>
        </st-modal>
    </div>
</template>

<script>
import BeneficiaryForm from "@/modules/applications/components/beneficiary/BeneficiaryForm";

export default {
    name: 'BeneficiaryModal',
    props: {
        item: {
            type: Object,
            required: false
        },
        items: {
            type: Array,
        },
    },
    components: {
        BeneficiaryForm
    },
    computed: {
        modalTitle() {
            return this.item ? this.$t('BENEFICIARY.MODAL.EDIT_HEADER') : this.$t('BENEFICIARY.MODAL.ADD_HEADER') 
        },
    },
    methods: {
        hide() {
            this.$refs['modal'].hide();
        },
        show() {
            this.$refs['modal'].show();
        },
        submit(data) {
            this.$emit('processBeneficiary', data);
            this.hide();
        },
        cancel() {
            this.$emit('cancel');
            this.hide();
        }
    }
};
</script>
