<template>
    <div>
        <Loader v-if="isLoading"/>
        <st-modal
            id="st-document-series-allocation-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="xl"
            hideable
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                    {{headerText}}
                </p>
            </template>
            <template #body>
                <ManageDocumentSeriesAllocationForm
                    ref="document-series-allocation-form"
                    :selectedDocumentSeries="selectedDocumentSeries"
                    :editMode="editMode"
                    @cancelForm="hide"
                    @submit="submit"
                >
                </ManageDocumentSeriesAllocationForm>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="manage"
                        :spinner="isLoading"
                    >
                        <span > {{ buttonText }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ManageDocumentSeriesAllocationForm from './ManageDocumentSeriesAllocationForm.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ManageDocumentSeriesAllocation',
    components: {
        ManageDocumentSeriesAllocationForm
    },
    props: {
        selectedDocumentSeries: {
            type: Object,
            default: () => ({}),
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            loading: "shared/loading",
        }),
        isLoading() {
            return this.loading['documentSeriesAllocation/upload'] || this.loading['documentSeriesAllocation/update'];
        },
        headerText() {
            return this.editMode ?
                this.$t('DOCUMENT_SERIES_ALLOCATION.FORM.EDIT_TITLE') :
                this.$t('DOCUMENT_SERIES_ALLOCATION.FORM.ADD_TITLE');
        },
        buttonText() {
            return this.editMode ?
                this.$t('GENERAL.BUTTON.UPDATE') :
                this.$t('GENERAL.BUTTON.ADD');
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        manage() {
            this.$refs['document-series-allocation-form'].manage();
        },
        submit() {
            this.$emit('update');
        },
    }
};
</script>
