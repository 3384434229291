<template>
    <div>
        <st-modal
            id="st-doc-series-details"
            hide-header-delimiter
            hide-footer-delimiter
            size="lg"
            hideable
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                    {{$t('DOCUMENT_SERIES.LIST.HEADER')}}
                </p>
            </template>
            <template #body>
                <document-series-filters >
                    <document-series-table/>
                </document-series-filters>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import DocumentSeriesFilters from './DocumentSeriesFilters.vue';
import DocumentSeriesTable from './DocumentSeriesTable.vue';

export default {
    name: 'DocumentSeriesDetailsModal',
    components: {
        DocumentSeriesFilters,
        DocumentSeriesTable
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
    }
};
</script>
