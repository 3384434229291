import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import GenericModel from '@/shared/models/generic-model';
import IntegerField from '@/shared/fields/integer-field';
import BooleanField from '@/shared/fields/boolean-field';

const field = (name) => i18n.t(`DOCUMENT_SERIES.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DOCUMENT_SERIES.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    name: new StringField(
        'name',
        field('name'),
        { required: true },
        {
            placeholder: placeholder('name'),
        },
    ),
    prefix: new StringField(
        'prefix',
        field('prefix'),
        { required: true },
    ),
    current_index: new IntegerField(
        'current_index',
        field('current_index'),
        { required: true, min: 1 },
    ),
    active: new BooleanField(
        'active',
        field('active'),
    ),
}

export class DocumentSeriesModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
