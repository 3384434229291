
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { DocumentSeriesAllocationService } from '@/modules/document-series-allocation/document-series-allocation-service';

export class DocumentSeriesAllocationUserField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            DocumentSeriesAllocationService.getUsersList,
            (record) => {
                if (!record) {
                    return '';
                }
                return {
                    value: record.created_by,
                    text: record.created_by,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
