<template>
    <div>
        <st-data-table
            :items="documentSeries"
            :fields="fields"
            :modelPresenter="presenter"
            :loading="loading['documentSeriesAllocation/fetchDocumentSeries']"
            hover
            customBooleanTypeField
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DocumentSeriesModel } from '@/modules/document-series-allocation/models/document-series-model';

const { fields } = DocumentSeriesModel;

export default {
    name: 'DocumentSeriesTable',
    data() {
        return {
            presenter: DocumentSeriesModel.presenter,
            fields: [
                fields.name.extendField({ doubleSize: true }),
                fields.prefix,
                fields.current_index,
                fields.active.extendField({ booleanType: true }),
            ],
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            documentSeries: 'documentSeriesAllocation/details/documentSeries',
        }),
    },
}
</script>
