<template>
    <form class="form"
        novalidate="novalidate"
        id="st_manage_document_series_allocation_form"
    >
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.type_id.label }} *
                    </label>
                    <st-select
                        v-model="model[fields.type_id.name]"
                        :ref="fields.type_id.name"
                        :field="fields.type_id"
                        :formValidation="fv"
                        :disabled="editMode"
                        searchable
                    />
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.issued_date.label }} *
                    </label>
                    <st-datepicker
                        v-model="model[fields.issued_date.name]"
                        :ref="fields.issued_date.name"
                        :name="fields.issued_date.name"
                    />
                </div>
            </div>
        </div>

        <div class="row" v-if="editMode">
            <div class="col-12">
                <div class="form-group">
                    <label>
                        {{ fields.allocated_series.label }}
                    </label>
                    <st-input-text
                        v-model="model[fields.allocated_series.name]"
                        :ref="fields.allocated_series.name"
                        :name="fields.allocated_series.name"
                        :disabled="editMode"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="upload-files">
                    <label>{{ $t("DOCUMENT_SERIES_ALLOCATION.FIELDS.FILE") }} *</label>
                    <st-file
                        :maxFileSize="1000"
                        :maxFiles="1"
                        :value="allDocuments"
                        @change="onFileChange"
                        :token="downloadToken"
                        :isValid="documentState"
                        :wasValidated="true"
                    ></st-file>
                </div>
            </div>
        </div>

        <div class="row mt-15">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.tax_value.label }}
                    </label>
                    <st-input-text
                        v-model="model[fields.tax_value.name]"
                        :ref="fields.tax_value.name"
                        :name="fields.tax_value.name"
                    />
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.availability_period_months.label }}
                    </label>
                    <st-input-text
                        v-model="model[fields.availability_period_months.name]"
                        :ref="fields.availability_period_months.name"
                        :name="fields.availability_period_months.name"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.execution_duration.label }}
                    </label>
                    <st-input-text
                        v-model="model[fields.execution_duration.name]"
                        :ref="fields.execution_duration.name"
                        :name="fields.execution_duration.name"
                    />
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.execution_start_date.label }}
                    </label>
                    <st-datepicker
                        v-model="model[fields.execution_start_date.name]"
                        :ref="fields.execution_start_date.name"
                        :name="fields.execution_start_date.name"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.extension_date.label }}
                    </label>
                    <st-datepicker
                        v-model="model[fields.extension_date.name]"
                        :ref="fields.extension_date.name"
                        :name="fields.extension_date.name"
                    />
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.investition_value.label }}
                    </label>
                    <st-input-text
                        v-model="model[fields.investition_value.name]"
                        :ref="fields.investition_value.name"
                        :name="fields.investition_value.name"
                    />
                </div>
            </div>
        </div>

        <div class="row" v-if="beneficiariesLoaded">
            <div class="col-12 mt-15">
                <beneficiary-list
                    ref="beneficiaryList"
                    :viewMode="false"
                    :isExternalDocument="true"
                    @changeList="updateBeneficiaryList"
                />
            </div>
        </div>

        <div class="row" v-if="realEstatesLoaded">
            <div class="form-group mt-15">
                <label class="font-size-h6 font-weight-bold">
                    {{ $t("DOCUMENT_SERIES_ALLOCATION.FORM.LOCATION_TYPES.LABEL") }}
                </label>
                <b-form-radio-group
                    size="lg"
                    v-model="selectedLocationType"
                    :options="locationTypes"
                    name="radio-options-location-types"
                ></b-form-radio-group>
            </div>

            <div class="col-12" v-if="selectedLocationType==locationTypePinpoint">
                <DocumentSeriesAllocationLocations
                    ref="allocationLocationsList"
                    :locations="locations"
                    @locationsUpdated="locationsUpdated"
                    @reloadLocationsMap="reloadLocationsMap"
                ></DocumentSeriesAllocationLocations>
            </div>

            <div class="col-12 mt-15" v-if="selectedLocationType==locationTypeRealEstates">
                <real-estate-list
                    ref="realEstateList"
                    :viewMode="false"
                    @changeList="updateRealEstateList"
                    :setDefault="true"
                />
            </div>

            <div class="col-12 allocation-locations-map">
                <DocumentSeriesAllocationLocationsMap
                    ref="allocationLocationsMap"
                    :selectedLocationType="selectedLocationType"
                    :locations="locations"
                    :realEstates="real_estate_target"
                ></DocumentSeriesAllocationLocationsMap>
            </div>
        </div>

    </form>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { mapActions, mapGetters } from 'vuex';
import Message from '@/shared/message/message';
import { FormSchema } from '@/shared/form/form-schema';
import { CommonService } from '@/shared/services/common.service';
import { DocumentSeriesAllocationModel } from '@/modules/document-series-allocation/document-series-allocation-model';
import DocumentSeriesAllocationLocations from './document-series-locations/DocumentSeriesAllocationLocations.vue';
import DocumentSeriesAllocationLocationsMap from './document-series-locations/DocumentSeriesAllocationLocationsMap.vue';
import BeneficiaryList from "@/modules/applications/components/beneficiary/BeneficiaryList.vue";
import RealEstateList from "@/modules/applications/components/real-estate/RealEstateList.vue";

const { fields } = DocumentSeriesAllocationModel;
const formSchema = new FormSchema([
    fields.type_id,
    fields.allocated_series,
    fields.allocation_recipient_id,
    fields.issued_date,
    fields.address,
    fields.locality_name,
    fields.territorial_administrative_unit_id,
    fields.identification_number,
    fields.tax_value,
    fields.availability_period_months,
    fields.execution_duration,
    fields.execution_start_date,
    fields.extension_date,
    fields.investition_value,
]);

const LOCATION_TYPE_REAL_ESTATES = 'real_estate_list';
const LOCATION_TYPE_PINPOINT = 'pinpoint';

export default {
    name: 'ManageDocumentSeriesAllocationForm',
    props: {
        selectedDocumentSeries: {
            type: Object,
            default: () => ({}),
        },
        editMode: {
            type: Boolean,
            default: false
        },
    },
    components: {
        DocumentSeriesAllocationLocationsMap,
        DocumentSeriesAllocationLocations,
        BeneficiaryList,
        RealEstateList,
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: {},
            fields,
            fv: null,
            document: null,
            allDocuments: [],
            documentState: null,
            address: '',
            locationTypes: [
                {
                    text: this.$t("DOCUMENT_SERIES_ALLOCATION.FORM.LOCATION_TYPES.PINPOINT"),
                    value: LOCATION_TYPE_PINPOINT,
                },
                {
                    text: this.$t("DOCUMENT_SERIES_ALLOCATION.FORM.LOCATION_TYPES.REAL_ESTATE_LIST"),
                    value: LOCATION_TYPE_REAL_ESTATES,
                },
            ],
            locationTypeRealEstates: LOCATION_TYPE_REAL_ESTATES,
            locationTypePinpoint: LOCATION_TYPE_PINPOINT,
            selectedLocationType: null,
            locations: [],  // tip amplasamente locatie
            real_estate_target: [], // tip amplasamente CF/real_estate_target list
            beneficiary_users: [],
            realEstatesLoaded: false,
            beneficiariesLoaded: false,
        };
    },
    watch: {
        document(newFile) {
            this.documentState = newFile instanceof File;
        },
    },
    computed: {
        ...mapGetters({
            filters: 'documentSeriesAllocation/list/filters',
            uploadToken: 'documentSeriesAllocation/list/uploadAccessToken',
            downloadToken: 'documentSeriesAllocation/list/downloadAccessToken',
        }),
    },
    methods: {
        ...mapActions({
            doCreate: 'documentSeriesAllocation/form/create',
            doUpdate: 'documentSeriesAllocation/form/update',
            doUpload: 'documentSeriesAllocation/form/upload',
            setRecord: 'documentSeriesAllocation/form/doSetRecord',
            setAvailableUatIds: 'documentSeriesAllocation/form/doSetAvailableUatIds',
            doFetch: 'documentSeriesAllocation/list/doFetch',
        }),
        clearForm() {
             this.model = formSchema.initialValues({});
             this.document = null;
        },
        hide() {
            this.$emit('cancelForm');
            this.clearForm();
        },
        async manage() {
            const validate = await this.fv.validate();
            if (validate === 'Valid' && this.documentState) {
                const uats = this.fields.territorial_administrative_unit_id.options;
                const selectedUat = uats.find( uat => uat.value === this.model.territorial_administrative_unit_id);
                let uat = {};
                if (selectedUat) {
                    uat.locality_id = selectedUat.value;
                    uat.locality_name = selectedUat.text;
                }

                let allocation_recipient_id = this.model.allocation_recipient_id;

                if (this.document && this.document instanceof File) {
                    const formData = new FormData();
                    formData.append("files", this.document);

                    const uploadedFiles = await this.doUpload({
                        files: formData,
                        uploadAccessToken: this.uploadToken
                    });
                    allocation_recipient_id = uploadedFiles[0]?.id;
                }

                const payload = {
                    allocation_recipient_id,
                    address: this.model.address,
                    locality_name: this.model.locality_name,
                    uat,
                    identification_number: this.model.identification_number,
                    tax_value: this.model.tax_value,
                    availability_period_months: this.model.availability_period_months,
                    execution_duration: this.model.execution_duration,
                    execution_start_date: this.model.execution_start_date,
                    extension_date: this.model.extension_date,
                    investition_value: this.model.investition_value,
                    // from app components
                    real_estate_target: this.selectedLocationType == LOCATION_TYPE_REAL_ESTATES ? this.real_estate_target : [],
                    beneficiary_users: this.beneficiary_users,
                    location: this.selectedLocationType == LOCATION_TYPE_PINPOINT && this.locations.length > 0 ? this.locations[0] : null,
                    locations: this.selectedLocationType == LOCATION_TYPE_PINPOINT ? this.locations : null,
                };

                if (this.editMode) {
                    // Edit
                    this.doUpdate({id: this.selectedDocumentSeries.id, payload})
                    .then(() => {
                        Message.success('GENERAL.UPDATED_SUCCESS');
                        this.hide();
                        this.$emit('submit');
                    });
                } else {
                    // Create
                    payload.type_id = this.model.type_id;
                    this.doCreate(payload).then((data) => {
                        this.hide();
                        this.showSuccessModal(data);
                        this.$emit('submit');
                    });
                }
            }
        },
        validateDocument() {
            this.documentState = this.document && this.document instanceof File;
        },
        showSuccessModal(id) {
            const title = this.$t('DOCUMENT_SERIES_ALLOCATION.NOTIFIERS.CREATED_SUCCESS_TITLE');
            const text = `${this.$t('DOCUMENT_SERIES_ALLOCATION.NOTIFIERS.CREATED_SUCCESS_TEXT', {number: id})}`;
            this.$alert({
                type: 'success',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
            });
        },
        onFileChange(documents) {
            if (documents?.length && documents.length === 1) {
                this.document = documents[0];
            } else {
                this.documentState = false;
            }
        },
        updateBeneficiaryList(values) {
            this.beneficiary_users = values;
            this.selectedDocumentSeries.beneficiary_users = values;
            this.selectedDocumentSeries.form.beneficiary_users = values;
        },
        updateRealEstateList(values) {
            this.real_estate_target = values;
            this.selectedDocumentSeries.real_estate_target = values;
            this.selectedDocumentSeries.form.real_estate_target = values;
        },
        locationsUpdated(locs) {
            this.locations = locs;
        },
        reloadLocationsMap() {
            this.$nextTick(() => {
                this.$refs.allocationLocationsMap.loadMap();
            });
        },
    },
    created() {
        if (this.editMode) {
            const record = this.selectedDocumentSeries;

            if (record?.address) {
                this.address = record.address;
            }

            this.locations = record.locations ?? [];
            if (record?.location) {
                this.location = record.location;
                // fill in/transform single old location to the location list (in case of unedited old records)
                if(!record.locations?.length) {
                    this.locations.push({
                        ...record.location,
                        identification_number: record.identification_number,
                        territorial_administrative_unit: record.territorial_administrative_unit ?? '-',
                        territorial_administrative_unit_id: record.territorial_administrative_unit_id,
                        centroid: {
                            x: record.location?.longitude_stereo70,
                            y: record.location?.latitude_stereo70,
                        },
                        key: `${record.territorial_administrative_unit_id}_${record.identification_number}`,
                    });
                }
            }

            if (record?.allocation_recipient_id) {
                this.allDocuments = [{
                    file_id: record.allocation_recipient_id,
                    filename: "Document"
                }];
                this.documentState = true;
            }

            if (record?.uat) {
                record.territorial_administrative_unit_id = record.uat.locality_id;
                this.selectedDocumentSeries.territorial_administrative_unit_id = record.uat.locality_id;
            }

            this.real_estate_target = record.real_estate_target ?? [];
            this.beneficiary_users = record.beneficiary_users ?? [];

            // we need to keep these in the "form" property to be able to use with the app module carousels
            record.form = {
                'beneficiary_users': this.beneficiary_users,
                'real_estate_target': this.real_estate_target,
            }

            this.setRecord(record);

            this.model = formSchema.initialValues(record);
        } else {
            this.selectedDocumentSeries.form = {
                'beneficiary_users': [],
                'real_estate_target': [],
            };
            this.setRecord(this.selectedDocumentSeries);
        }

        this.selectedLocationType = this.real_estate_target?.length > 0 ? LOCATION_TYPE_REAL_ESTATES : LOCATION_TYPE_PINPOINT;

        setTimeout(() => {
            this.beneficiariesLoaded = true;
            this.realEstatesLoaded = true;
        }, 500);
    },
    async mounted() {
        this.fv = createFormValidation('st_manage_document_series_allocation_form', this.rules);

        const uats = await CommonService.territorialAdministrativeUnitsListAutocomplete();
        this.setAvailableUatIds(uats);
    },
};
</script>
