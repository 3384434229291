<template>
    <form class="form"
        novalidate="novalidate"
        id="st_manage_document_series_allocation_location_form"
    >
        <div class="row mt-6">
            <div class="col-12 col-md-5">
                <div class="form-group">
                    <label>
                        {{ fields.territorial_administrative_unit_id.label }}
                    </label>
                    <st-autocomplete
                        v-model="model[fields.territorial_administrative_unit_id.name]"
                        :options="fields.territorial_administrative_unit_id.options"
                        :ref="fields.territorial_administrative_unit_id.name"
                        :name="fields.territorial_administrative_unit_id.name"
                        :fields="fields"
                        :model="model"
                    ></st-autocomplete>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="form-group">
                    <label>
                        {{ fields.identification_number.label }}
                    </label>
                    <st-input-text
                        v-model="model[fields.identification_number.name]"
                        :ref="fields.identification_number.name"
                        :name="fields.identification_number.name"
                    />
                </div>
            </div>
        </div>

        <div class="row mt-6">
            <div class="mx-4">
                <i class="mr-2 fas fa-info-circle text-primary"></i>
                <span class="font-weight-normal">{{$t('DOCUMENT_SERIES_ALLOCATION.FORM.TITLE_TOOLTIP')}}</span>
            </div>
            <div class="col-12 mt-4">
                <div class="preview-atlas-map-wrapper">
                    <st-map
                        ref="allocationLocationMap"
                        :showLayers="false"
                        :showLineMeasurement="false"
                        :captureClickLocation="true"
                        @areaMeasured="areaMeasured"
                    ></st-map>
                </div>
            </div>
        </div>

    </form>
</template>

<script>
import Notify from "@/shared/utils/notify";
import { mapActions, mapGetters } from 'vuex';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { FormSchema } from '@/shared/form/form-schema';
import { CommonService } from '@/shared/services/common.service';
import { DocumentSeriesAllocationLocationModel } from '@/modules/document-series-allocation/document-series-allocation-location-model';
import /* webpackChunkName: "atlas" */ StMap from '@/shared/components/StMap.vue';

const { fields } = DocumentSeriesAllocationLocationModel;

const formSchema = new FormSchema([
    fields.address,
    fields.locality_name,
    fields.territorial_administrative_unit_id,
    fields.identification_number,
]);

export default {
    name: 'DocumentSeriesAllocationLocationsForm',
    components: {
        StMap,
    },
    props: {
        selectedLocation: {
            type: Object,
            default: () => ({}),
        },
        editMode: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: {},
            fields,
            fv: null,
            address: '',
        };
    },
    watch: {
        // measurement(val) {},
    },
    computed: {
        ...mapGetters({
            locations: 'documentSeriesAllocation/locations/rows',
            activeLocation: 'documentSeriesAllocation/locations/activeLocation',
            measurement: 'applications/atlas/measurement',
        }),
    },
    methods: {
        ...mapActions({
            add: 'documentSeriesAllocation/locations/doAddLocation',
            update: 'documentSeriesAllocation/locations/doUpdateLocation',
            setAvailableUatIds: 'documentSeriesAllocation/form/doSetAvailableUatIds',
        }),
        clearForm() {
             this.model = formSchema.initialValues({});
        },
        manageLocation() {
            const uat_id = this.model[this.fields.territorial_administrative_unit_id.name];
            const identification_number = this.model[this.fields.identification_number.name];

            let action = 'update';

            if(!this.editMode) {
                action = 'add';
                const comboAlreadyAdded = this.locations?.some( (v) => {
                    return v.territorial_administrative_unit_id == uat_id && v.identification_number == identification_number
                });

                if(comboAlreadyAdded) {
                    Notify("warning", this.$t("DOCUMENT_SERIES.NOTIFIERS.LOCATION_ALREADY_ADDED"));
                    return;
                }
            }

            const uats = this.fields.territorial_administrative_unit_id.options;
            const selectedUat = uats.find( uat => uat.value === this.model.territorial_administrative_unit_id);

            let uat = {};
            if (selectedUat) {
                uat.locality_id = selectedUat.value;
                uat.locality_name = selectedUat.text;
            }

            let updateKey;
            let key = `${uat_id}_${identification_number}`;
            if(this.editMode) {
                // use active record's key to search, since we don't have IDs.
                updateKey = `${this.activeLocation.territorial_administrative_unit_id}_${this.activeLocation.identification_number}`;
            } else {
                updateKey = key;
            }

            const centroid = this.measurement ? this.measurement.centroid : this.activeLocation?.centroid;
            const coordinates = this.measurement ? this.measurement.coordinates : this.activeLocation?.coordinates;

            this[action]({
                uat,
                territorial_administrative_unit: uat.locality_name,
                territorial_administrative_unit_id: uat_id,
                identification_number: identification_number,
                key,
                updateKey,
                centroid,
                coordinates,
            });

            this.$emit('cancelForm');
        },
        loadMap() {
            // unused right now
            if(this.activeLocation.coordinates) {
                this.$refs.allocationLocationMap.loadMap([{
                    coordinates: this.activeLocation.coordinates,
                    cadastral_number: this.activeLocation.identification_number,
                    text: `${this.activeLocation.territorial_administrative_unit}, ${this.activeLocation.identification_number}`,
                }]);
            } else if (this.activeLocation.centroid) {
                this.$refs.allocationLocationMap.loadMap(null, null, [{
                    centroid: this.activeLocation.centroid,
                    cadastral_number: this.activeLocation.identification_number,
                    description: this.activeLocation.uat?.locality_name,
                }]);
            }
        },
        areaMeasured(measureResult) {
            //TODO: figure out why doesn't work from STmap
        }
    },
    created() {
        const record = this.activeLocation ?? {};
        this.model = formSchema.initialValues(record);
    },
    async mounted() {
        this.fv = createFormValidation('st_manage_document_series_allocation_location_form', this.rules);

        const uats = await CommonService.territorialAdministrativeUnitsListAutocomplete();
        this.setAvailableUatIds(uats);

        if(this.activeLocation?.centroid) {
            this.$nextTick(() => {
                this.loadMap();
            });
        }
    },
};
</script>
