<template>
    <st-filters-pagination
        stateModule="documentSeriesAllocation/list"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :header="$t('DOCUMENT_SERIES_ALLOCATION.LIST.TITLE')"
        :moreFilters="false"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { DocumentSeriesAllocationModel } from '@/modules/document-series-allocation/document-series-allocation-model';

    const { fields } = DocumentSeriesAllocationModel;

    const filterSchema = new FilterSchema([
        fields.allocated_series,
        fields.created_by_filter,
        fields.type_id,
        fields.created_date,
    ]);

    export default {
        name: 'DocumentSeriesAllocationListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                currentPage: 1,
            };
        },
        computed: {
            ...mapGetters({
               total: 'documentSeriesAllocation/list/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'documentSeriesAllocation/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },

    };
</script>

<style scoped>

</style>

