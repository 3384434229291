<template>
    <div class="col-12 mt-15">
        <label>
            {{ $t("DOCUMENT_SERIES_ALLOCATION.FIELDS.LOCATION") }}
        </label>
        <div class="allocation-map-placeholder" v-if="!showMap">
            <span>{{ $t('APPLICATION.TERRITORIAL_ATLAS.PLACEHOLDER') }}</span>
        </div>
        <div class="preview-allocation-map-wrapper" v-show="showMap">
            <st-map
                ref="allocationMap"
                :showLayers="false"
                :showMeasurement="false"
            ></st-map>
        </div>
        <b-row class="mt-6" v-if="locationList.length">
            <b-col :cols="10">
                <div class="form-group">
                    <label class="mb-4 d-block">
                        {{ $t('APPLICATION.TERRITORIAL_ATLAS.REAL_ESTATES_LABEL') }}
                    </label>
                    <template v-for="loc in locationList">
                        <div :key="loc.key"
                            :class="[`st-acard-item shadow-sm d-inline-flex cursor-pointer`, activeLocation?.key === loc.key ? 'st-acard-item-active' : '']"
                            @click="zoomToAllocationLocation(loc)"
                        >
                            <div class="d-inline align-middle mt-2">
                                {{ loc.text }}
                            </div>
                            <div v-if="isParcelsSelected" class="d-inline ml-4 mt-2" @click="redirectToAtlasPage(loc)">
                                <i class="fa fa-eye text-primary align-middle"></i>
                            </div>
                        </div>
                    </template>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import /* webpackChunkName: "atlas" */ StMap from '@/shared/components/StMap.vue';

const LOCATION_TYPE_REAL_ESTATES = 'real_estate_list';

export default {
    name: 'DocumentSeriesAllocationLocationsMap',
    components: {
        StMap,
    },
    props: {
        locations: {
            type: Array,
            default: () => ([]),
        },
        realEstates: {
            type: Array,
            default: () => ([]),
        },
        selectedLocationType: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            activeLocation: null,
        };
    },
    watch: {
        locationList() {
            this.loadMap();
        },
    },
    computed: {
        locationList() {
            return this.selectedLocationType == LOCATION_TYPE_REAL_ESTATES
                ? this.realEstates.map((el) => ({
                    ...el,
                    text: `${el.cadastral_number} ${el.territorial_administrative_unit}`,
                    siruta: el.territorial_administrative_unit,
                }))
                : this.locations.map((el) => ({
                    ...el,
                    cadastral_number: el.identification_number,
                    text: `${el.identification_number} ${el.territorial_administrative_unit}`,
                    siruta: el.territorial_administrative_unit,
                }))
        },
        isParcelsSelected() {
            return this.selectedLocationType == LOCATION_TYPE_REAL_ESTATES;
        },
        showMap() {
            return this.locationList?.length > 0;
        },
    },
    methods: {
        isActive(loc) {
            return loc.key == this.activeLocation?.key;
        },
        loadMap() {
            this.$nextTick(() => {
                const locationsWithPolygons = this.locationList.filter(v => v.coordinates?.length > 0);
                const locationsWithPinpoints = this.locationList.filter(v => !v.coordinates?.length && v.centroid);
                this.$refs.allocationMap.loadMap(locationsWithPolygons, null, locationsWithPinpoints);
            });
        },
        redirectToAtlasPage(selectedItem) {
            let routeData = this.$router.resolve({
                name: 'atlas',
                query: {
                    cadastral_number: selectedItem.cadastral_number,
                    siruta: selectedItem.siruta,
                    app_type: this.record?.application_type_id ?? this.selectedApplicationType,
                }
            });
            window.open(routeData.href, '_blank');
        },
        zoomToAllocationLocation(location) {
            if(!location.centroid?.x && !location.coordinates?.x) {
                return;
            }

            this.activeLocation = location;

            this.$refs.allocationMap.zoomToCoordonates({
                x: location.centroid?.x,
                y: location.centroid?.y,
            }, {
                duration: 1000,
                zoomLevel: 18,
            });
        }
    },
    mounted() {
        this.loadMap();
    }
};
</script>

<style scoped>
.st-acard-item {
    padding: 4px 8px;
    background-color: white !important;
    border: 1px solid rgb(226, 230, 234);
    margin-left: 4px;
    border-radius: 4px;
}

.st-acard-item:hover, .st-acard-item-active {
    border-color: #1EA7FD50;
    box-shadow: rgba(0, 0, 0, 0.08) 0 3px 10px 0;
}

</style>

