import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`DOCUMENT_SERIES_ALLOCATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DOCUMENT_SERIES_ALLOCATION.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    address: new StringField('address', label('address')),
    locality_name: new StringField('locality_name', label('locality')),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit_id'),
        { autocompleteRequired: false },
        { placeholder: placeholder('territorial_administrative_unit_id')},
    ),
    identification_number: new StringField('identification_number', label('identification_number')),
};

export class DocumentSeriesAllocationLocationModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
