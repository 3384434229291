<template>
    <st-filters-pagination
        stateModule="documentSeriesAllocation/details"
        :total="total"
        :filters="filters"
        :moreFilters="false"
        :showErase="false"
        @change="doFilter"
    >
        <template #filters-toolbar>
            <slot name="toolbar">
            </slot>
        </template>
        <div class="mt-4">
            <slot></slot>
        </div>
    </st-filters-pagination>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { DocumentSeriesModel } from '../../models/document-series-model';

const { fields } = DocumentSeriesModel;

const filterSchema = new FilterSchema([
    fields.name,
]);

export default {
    name: 'DocumentSeriesFilters',
    data() {
        return {
            filters: filterSchema.fields,
        }
    },
    computed: {
        ...mapGetters({
            total: 'documentSeriesAllocation/details/total',
        }),
    },
    methods: {
        ...mapActions({
            doFetch: 'documentSeriesAllocation/details/fetchDocumentSeries',
        }),
        refresh() {
            this.doFilter(this.params);
        },
        doFilter(params) {
            this.params = params;
            this.doFetch(params);
        },
    },
}
</script>
