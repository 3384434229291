import { render, staticRenderFns } from "./DocumentSeriesTable.vue?vue&type=template&id=1e3b739a&"
import script from "./DocumentSeriesTable.vue?vue&type=script&lang=js&"
export * from "./DocumentSeriesTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports